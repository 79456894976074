<template lang="pug">
.z-30.bg-white.shadow.top-5.left-5.absolute.grid.grid-cols-3.pl-6.pr-4.pt-5.grid-rows-layout.pb-8(
  style='width: 450px'
)
  .col-start-1.col-end-3.font-bold.text-xl.text-darkblue.flex.justify-between.mb-4
    span.font-SourceSansPro {{ $t("routesTrips.trip.editGeozone.title") }}
  .flex.justify-end.col-start-3.items-center
    i.el-icon-close.text-2xl.cursor-pointer.text-darkblue.font-semibold(
      @click='$emit("close"), $store.commit("geozones/END_DRAWING"), $store.dispatch("races/END_EDITING", { value: true, geozone })'
    )
  .flex.justify-end.col-start-1.col-end-4.border-b.border-divider.pb-6.mt-6
    skif-button(
      variant='normal',
      @click='$store.commit("geozones/END_DRAWING"), $emit("close"), $store.dispatch("races/END_EDITING", { value: true, geozone })',
      type='button'
    ) {{ $t("btn.cancel") }}
    skif-button.ml-6(
      @click='getEditGeo(), $emit("my-done"); $store.commit("geozones/END_DRAWING"), $store.dispatch("races/END_EDITING", { value: true, geozone })',
      type='button'
    ) {{ $t("btn.save") }}
  span.col-start-1.text-base.font-semibold.text-annotationColor.font-SourceSansPro.py-3 {{ $t("routesTrips.trip.editGeozone.figure") }}
  button.mt-2.col-start-1.col-end-3.h-9.border.focus_outline-none.rounded.focus_border-darkblue.transition-colors.duration-200.font-bold.text-sm.text-someGrey.mb-5(
    type='button',
    style='width: 300px'
  )
    .flex.items-center.justify-between.px-3
      span {{ getGeozoneType(geozone.type.key) }}

  transition(
    enter-active-class='duration-300',
    leave-active-class='duration-300',
    enter-to-class='overflow-hidden max-h-130',
    leave-class='overflow-hidden max-h-130',
    leave-to-class='overflow-hidden max-h-0',
    enter-class='overflow-hidden max-h-0'
  )
    ul.col-start-1.col-end-3.border.border-divider.rounded-sm.mt-1(
      style='width: 300px',
      v-show='isTypeShowen'
    )
      li.leading-8.h-8.block.cursor-pointer.px-3.font-bold.text-sm.hover_bg-reallylightblue(
        v-for='item in geozoneType',
        :key='item.key',
        @click='geozone.type.key = item.key; $store.commit("races/SHAPE_CHANGE", geozone.type.key); geozone.width = 1; isTypeShowen = false',
        :class='geozone.type.key === item.key ? "text-textmain" : "text-someGrey"'
      ) {{ item.value }}
  span.col-start-1.text-base.font-semibold.text-annotationColor.font-SourceSansPro.py-3 {{ $t("routesTrips.trip.editGeozone.figure") }}
  el-input.col-start-1.col-end-3(
    style='width: 300px',
    v-model='geozone.name',
    :placeholder='$t("geozones.name")'
  )
</template>
<script>
import { mapState, mapGetters } from 'vuex'

export default {
  data() {
    return {
      isActive: false,

      geozone: {
        name: 'Геозона',
        type: {
          key: '',
          type: 'geozone_type',
          value: ''
        },

        geometry: []
      },
      isTypeShowen: false,
      isIconShowen: false
    }
  },
  computed: {
    ...mapState('dictionary', {
      geozoneType: (state) => state.geozone_type,
      geozoneTypes: (state) => state.geo_icon,
      colors: (state) => state.color_code
    }),
    // ...mapState('geozones', {
    //   currentGeozone: (state) => state.currentGeozone,
    // }),
    ...mapState('routes', {
      geoToEdit: (state) => state.geoToEdit
    }),
    ...mapState('races', {
      editedGeozone: (state) => state.editedGeozone
    }),
    ...mapGetters('dictionary', ['getGeozoneType', 'getGeozoneIcon'])
  },
  watch: {
    // editedGeozone(geo) {
    //   console.log('ediiiiiited geo ', geo)
    // },
    // currentGeozone(val) {
    //   switch (this.geozone.type.key) {
    //     case 'point':
    //       this.geozone.geometry = [val.latlng]
    //       break
    //     case 'circle':
    //       this.geozone.width = val.radius
    //       this.geozone.geometry = [val.latlng]
    //       break
    //     default:
    //       this.geozone.geometry = val.latlng
    //       break
    //   }
    // },
  },

  methods: {
    clearFields() {
      this.geozone.name = 'Геозона'
      this.geozone.width = 1
    },

    save() {
      // this.geozoneType.forEach(geo=> {
      //   if(geozone.)
      // })
      // console.log('geozone to get radius', this.geozone)
      const component = this
      switch (this.geozone.type.key) {
        case 'polygon':
          this.geozone.id = this.geoToEdit.id
          this.geozone.geometry.length < 3
            ? this.$showError(this.$t('geozones.errors.min_dots_polygon'))
            : this.geozone.name === ''
            ? this.$showError(this.$t('geozones.errors.name'))
            : // : this.$store
              //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
              //     .then((_) => component.$emit('close'))
              (this.geozone.type.value = this.geozoneType[2].value) &&
              this.$store
                .dispatch('races/EDITED_GEOZONE', this.geozone)
                .then(() => component.$emit('edited-geo', this.geozone))
                // .then(() => this.$store.dispatch('races/END_EDITING',  { value: true, component.geozone }))
                .then((_) => component.$emit('close'))

          break
        case 'circle':
          this.geozone.id = this.geoToEdit.id

          !this.geozone.width || this.geozone.geometry.length === 0
            ? this.$showError(this.$t('geozones.errors.draw_circle'))
            : this.geozone.name === ''
            ? this.$showError(this.$t('geozones.errors.name'))
            : // : this.$store
              //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
              //     .then((_) => component.$emit('close'))
              (this.geozone.type.value = this.geozoneType[0].value) &&
              this.$store
                .dispatch('races/EDITED_GEOZONE', this.geozone)
                .then(() => component.$emit('edited-geo', this.geozone))
                // .then(() => this.$store.dispatch('races/END_EDITING', true))
                .then((_) => component.$emit('close'))
          break
        case 'line':
          this.geozone.id = this.geoToEdit.id
          this.geozone.geometry.length < 2
            ? this.$showError(this.$t('geozones.errors.min_dots_line'))
            : this.geozone.name === ''
            ? this.$showError(this.$t('geozones.errors.name'))
            : this.geozone.width < 1
            ? this.$showError(this.$t('geozones.errors.width'))
            : // : this.$store
              //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
              //     .then((_) => component.$emit('close'))
              (this.geozone.type.value = this.geozoneType[1].value) &&
              this.$store
                .dispatch('races/EDITED_GEOZONE', this.geozone)
                .then(() => component.$emit('edited-geo', this.geozone))
                // .then(() => this.$store.dispatch('races/END_EDITING', true))
                .then((_) => component.$emit('close'))
          break

        case 'point':
          this.geozone.id = this.geoToEdit.id
          this.geozone.geometry.length < 0
            ? this.$showError(this.$t('geozones.errors.put_marker'))
            : this.geozone.name === ''
            ? this.$showError(this.$t('geozones.errors.name'))
            : // : this.$store
              //     .dispatch('geozones/CREATE_GEOZONE', this.geozone)
              //     .then((_) => component.$emit('close'))
              (this.geozone.type.value = this.geozoneType[3].value) &&
              this.$store
                .dispatch('races/EDITED_GEOZONE', this.geozone)
                .then(() => component.$emit('edited-geo', this.geozone))
                // .then(() => this.$store.dispatch('races/END_EDITING', true))
                .then((_) => component.$emit('close'))
          break
        default:
          break
      }
      this.$store.commit('geozones/END_DRAWING')
    },
    getEditGeo() {
      const component = this
      switch (this.geozone.type.key) {
        case 'circle':
          this.geozone.width = this.editedGeozone.radius
          this.geozone.geometry = this.editedGeozone.latlng
          this.$store
            .dispatch('races/EDITED_GEOZONE', this.geozone)
            .then(() => component.$emit('edited-geo', this.geozone))
            // .then(() => this.$store.dispatch('races/END_EDITING', true))
            .then((_) => component.$emit('close'))

          break
        case 'line':
          this.geozone.geometry = this.editedGeozone.latlng
          this.$store
            .dispatch('races/EDITED_GEOZONE', this.geozone)
            .then(() => component.$emit('edited-geo', this.geozone))
            // .then(() => this.$store.dispatch('races/END_EDITING', true))
            .then((_) => component.$emit('close'))
          break
        case 'polygon':
          this.geozone.geometry = this.editedGeozone.latlng
          this.$store
            .dispatch('races/EDITED_GEOZONE', this.geozone)
            .then(() => component.$emit('edited-geo', this.geozone))
            // .then(() => this.$store.dispatch('races/END_EDITING', true))
            .then((_) => component.$emit('close'))
          break
        case 'point':
          this.geozone.geometry = this.editedGeozone.latlng
          this.$store
            .dispatch('races/EDITED_GEOZONE', this.geozone)
            .then(() => component.$emit('edited-geo', this.geozone))
            // .then(() => this.$store.dispatch('races/END_EDITING', true))
            .then((_) => component.$emit('close'))
          break
      }
      this.$store.commit('geozones/END_DRAWING')
    }
  },
  created() {
    console.log('primary', this.geozone)
    this.geozone.type = this.geoToEdit.type
    this.geozone.name = this.geoToEdit.name
    this.geozone.geometry = this.geoToEdit.geometry
    this.geozone.width = this.geoToEdit.width
    this.geozone.id = this.geoToEdit.id
  }
}
</script>
